const ReleaseFlags = {
  // Enables social feed
  SOCIAL_FEED: 'social-feed',
  // Enables enhanced linkedin expertises evaluation
  ENHANCED_LINKEDIN_EXPERTISES_EVALUATION:
    'enhanced-linkedin-expertises-evaluation',
  // Activate OneSearch
  ONE_SEARCH: 'one-search',
  // Enables the recruiter import freelancers feature
  RECRUITER_IMPORT_FREELANCERS: 'recruiter-import-freelancers',
  // Enables the `trust by` a recruiter for freelancers
  TRUST_BY: 'trust-by',
  // Enables batch messages
  BATCH_MESSAGES: 'batch-messages',
  // Enables recruiter shortlist
  JOB_SHORTLIST: 'job-shortlist',
  // Enables the contract type filter
  CONTRACT_TYPE_FILTER: 'contract-type-filter',
};

const KillSwitchFlags = {
  // Enables API rate limiting
  API_RATE_LIMIT: 'api-rate-limiting',
  // Enables enhancing document security checks in resolvers
  DOCUMENT_ACCESS_RESTRICTION: 'document-access-restriction',
  // Enables the job to scrap linkedin users data
  LINKEDIN_USER_SCRAPING: 'linkedin-scraping',
  // Enables the job to scrap linkedin companies data
  LINKEDIN_COMPANY_SCRAPING: 'linked-in-company-scrapping',
  // Disables automatic client generation
  DISABLE_AUTOMATIC_CLIENT_GENERATION: 'disable-automatic-client-generation',
  // Enables the recruiter job notifications
  JOB_NOTIFICATIONS: 'job-notifications',
  // Enables the jobs for scrapping
  SCRAP_JOBS: 'scrap-jobs',
  // Enables Malt scraping
  SCRAP_MALT: 'scrap-malt',
  // Enables admin features for recruiter
  ADMIN_RECRUITER_FEATURES: 'admin-recruiter-features',
  // For linkedin providers
  LINKEDIN_PROFILE_SCRAPIN_PROVIDER: 'linkedin-profile-scrapin-provider',
  LINKEDIN_PROFILE_GOODONE_PROVIDER: 'linkedin-profile-goodone-provider',
  LINKEDIN_COMPANY_SCRAPIN_PROVIDER: 'linkedin-company-scrapin-provider',
  // Enables the free trial
  RECRUITER_FREE_TRIAL: 'recruiter-free-trial',
  // Enables automated marketing message about jobs
  AUTO_JOBS_MESSAGE: 'auto-jobs-message',
  // Enables the profile intro generation
  PROFILE_INTRO_GENERATION: 'profile-intro-generation',
  // Enables linkedin SSO
  LINKEDIN_SSO: 'linkedin-sso',
};

export const FeatureFlags = { ...ReleaseFlags, ...KillSwitchFlags };
export type FeatureFlags = typeof FeatureFlags;
