import {
  Button,
  Flex,
  IconPhone,
  Modal,
  Text,
  useBoolean,
} from '@collective/ui';
import { useTranslation } from 'react-i18next';

type PhoneButtonProps = {
  phoneNumber?: string | null;
  shortVersion?: boolean;
};

export const PhoneButton = ({
  shortVersion,
  phoneNumber,
}: PhoneButtonProps) => {
  const { t } = useTranslation();
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useBoolean();

  if (!phoneNumber) {
    return null;
  }

  return (
    <>
      <Button
        variant="secondary"
        onClick={setIsPhoneModalOpen.on}
        leftIcon={<IconPhone />}
      >
        {shortVersion ? t('phoneModal.buttonShort') : t('phoneModal.button')}
      </Button>
      <PhoneModal
        phoneNumber={phoneNumber}
        isOpen={isPhoneModalOpen}
        onClose={setIsPhoneModalOpen.off}
      />
    </>
  );
};

type PhoneModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  phoneNumber: string;
};

export const PhoneModal = ({
  isOpen,
  onClose,
  phoneNumber,
}: PhoneModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showHeader
      title={t('phoneModal.title')}
      size="sm"
    >
      <Flex p="20px" pt="16px" gap={2} alignItems="center">
        <IconPhone color="rythm.600" size="sm" />
        <Text>{phoneNumber}</Text>
      </Flex>
    </Modal>
  );
};
