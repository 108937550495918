// Common tracking params
import { ContractGenerationStep } from './contract-generation';

export const PROJECT_SOURCE = {
  marketplace: 'marketplace',
  productled: 'productled',
};

/**
 * Let's try to keep the naming uniform on the action VERB
 * - 'submitted' should be at the end for a form submission event
 * - 'filling' should be for tracking intermediate steps of a form
 * - 'clicked' should be for button clicks
 * - 'opened' should be for menu/dropdown tracking
 */

export const UNKNOWN_STEP = 'unknown';

// contract generation
export const CONTRACT_GENERATION = {
  submitted: 'submitted contract',
  filling: 'filling contract',
  version: '03-23',
  steps: {
    [ContractGenerationStep.ProjectInformation]: 'main information',
    [ContractGenerationStep.ScopeOfWork]: 'scope of work',
    [ContractGenerationStep.Split]: 'members split',
    [ContractGenerationStep.Generate]: 'generate',
    [ContractGenerationStep.Finished]: 'finished screen',
  },
};

export const MISSION_EXTEND_CLICK = 'clicked on extend mission';

// common invoice
export const COMMON_INVOICE_SEND_PREVIEW_CLICKED = 'clicked send preview';
export const COMMON_INVOICE_SEND_TO_CLIENT_CLICKED = 'clicked send to client';
export const COMMON_INVOICE_SEND_TO_CLIENT_DONE =
  'sent common invoice to client';
export const NEW_COMMON_INVOICE_SEND_DOWNLOADED =
  'downloaded a new common invoice';
export const COMMON_INVOICE_SEND_REMINDER_TO_CLIENT =
  'sent common invoice reminder to client';
export const COMMON_INVOICE_SEND_PREVIEW_REMINDER =
  'sent common invoice reminder preview';
export const COMMON_INVOICE_ABROAD = 'common invoice for abroad company';

// profiling questions
export const PROFILING_QUESTIONS_DONE_EVENT = 'submitted profiling questions';
export const PROFILING_QUESTIONS_FILLING_EVENT = 'filling profiling questions';
export const PROFILING_QUESTIONS_STEPS = {
  experience: 'experience',
  workProfile: 'work profile',
};

// Case study
export const CASE_STUDY_SHARE_MODAL_VIEWED = 'viewed share case study modal';
export const CASE_STUDY_SHARE_CLICKED =
  'clicked share case study in case study modal';
export const CASE_STUDY_LINK_COPIED = 'copied case study link';
export const CASE_STUDY_CREATED_FROM_APPLICATION =
  'case study created from opportunity application';

// Settings & Invoices
export const INVOICE = {
  downloaded: 'downloaded an invoice',
  opened: 'opened an invoice',
  source: {
    invoiceList: 'invoice list',
    transactions: 'transactions',
  },
};
export const CHANGED_WORKSPACE = {
  changed: 'changed workspace',
  type: {
    quote: 'quote',
    invoice: 'invoice',
  },
};
export const COMMON_INVOICE = 'common invoice';
export const CREATE_COLLECTIVE_CLICKED = 'created a collective';
export const COMPANY_SETTINGS_CHANGED = 'changed company settings';
export const NOTIFICATION_SETTINGS = {
  changed: 'changed notification settings',
  setting: {
    newOpportunityEmail: 'new opportunity email',
    opportunityDeclinedEmail: 'opportunity declined email',
    opportunityReminder: 'opportunity reminder email',
    notificationNetworkRequests: 'network request email',
    notificationChatReminders: 'chat reminder email',
  },
};

// Availability
export const AVAILABILITY = {
  name: 'updated availabilities',
  until: {
    months_1: '1 month',
    months_3: '3 months',
    months_6: '6 months',
    custom: 'custom',
  },
};

// Projects
export const PROJECT_MATCHING_PREFERENCES_CHANGED =
  'changed matching preferences';
export const OPPORTUNITY_APPLIED_TO = 'applied to an opportunity';
export const OPPORTUNITY_APPLIED_AFTER_DECLINED =
  'applied after declining application';
export const OPPORTUNITY_DECLINED = 'declined an opportunity';
export const OPPORTUNITY_ARCHIVED = 'archived an opportunity';
export const PAYMENT_REQUEST_SUBMITTED = 'submitted a payment request';
export const MISSION_CLOSED = 'closed a mission';
export const PROJECT_CREATED = 'created a project';

export const OPPORTUNITY_SHARE_BUTTON_CLICKED =
  'clicked on share opportunity button';
export const OPPORTUNITY_SHARED = 'shared an opportunity';
export const SHARED_OPPORTUNITY_APPLIED = 'applied to a shared opportunity';

// Proposal
export const PROPOSAL = {
  created: 'created a proposal',
  duplicated: 'duplicated a proposal',
  shared: 'shared a proposal',
};

// Members
export const MEMBERS_INVITED = 'invited members';

// Collective page
export const COLLECTIVE_PAGE_UPDATED = {
  name: 'updated their collective page',
  section: {
    services: 'services',
    manifesto: 'manifesto',
    cover: 'cover',
    logo: 'logo',
    tagline: 'tagline',
    skills: 'skills',
    members: 'members',
  },
};
export const COLLECTIVE_PAGE_PREVIEWED = 'previewed their collective page';
export const CASE_STUDY = {
  created: 'created a case study',
  duplicated: 'duplicated a case study',
  published: 'published a case study',
  unpublished: 'unpublished a case study',
  duplicatedToAnotherWorkspace: 'duplicated to another workspace',
};

// Client interface pages
export const CLIENT_INTERFACE_SHORTLIST_LINK_EVENT =
  'clicked on see shortlist from client interface';
export const CLIENT_INTERFACE_CONTACT_SHORTLIST_EVENT =
  'contacted collective on shortlist from client interface';
export const CLIENT_INTERFACE_DECLINE_OPPORTUNITY_EVENT =
  'declined collective on shortlist from client interface';
export const CLIENT_INTERFACE_ACCEPT_PROPOSAL_EVENT =
  'accepted proposal from client interface';
export const CLIENT_INTERFACE_ACCEPT_PAYMENT_REQUEST_EVENT =
  'accepted payment request from client interface';
export const CLIENT_INTERFACE_SEE_INVOICE_EVENT =
  'opened invoice from client interface';

export const CLIENT_INTERFACE_EXTEND_MISSION =
  'extended mission from client interface';

// Workspace properties
export const WORKSPACE = {
  personal: 'personal',
  collective: 'collective',
};

export const UPDATED_CONTACT_LOGO = {
  event: 'updated contact company logo',
  source: {
    clientsList: 'clients list',
    homepage: 'homepage',
    missionsList: 'missions list',
    companyEditForm: 'company edit form',
  },
};

// User profile
export const PROFILE_PROCESS = {
  started: 'profile process started',
  failed: 'profile process failed',
  succeeded: 'profile process succeeded',
};

// Collective created on the fly
export const CREATE_COLLECTIVE_FOR_QUOTE = 'create collective for quote';
export const CREATE_COLLECTIVE_FOR_INVOICE = 'create collective for invoice';

// Recruiter
export const RECRUITER_OPENED_PRO_PLAN = 'recruiter opened pro plan';
export const RECRUITER_CLICKED_ON_UPGRADE_TO_PRO_PLAN =
  'recruiter clicked on upgrade button';
export const RECRUITER_SIGNUP_COMPLETE = 'signup_complete';
export const RECRUITER_LOGIN_COMPLETE = 'login_complete';
export const RECRUITER_SEARCH = 'search';
export const RECRUITER_FILTER = 'filter';
export const RECRUITER_PROFILE_VIEW = 'profile_view';
export const RECRUITER_CHAT_OPEN = 'chat_open';
export const RECRUITER_CHAT_SENT = 'chat_sent';
export const RECRUITER_JOB_POSTING_INITIATE = 'job_posting_initiate';
export const RECRUITER_JOB_POSTING_COMPLETE = 'job_posting_complete';
export const RECRUITER_JOB_SHARED_ON_LINKEDIN = 'job_shared_on_linkedin';
export const RECRUITER_SEARCH_SAVED = 'search_saved';
export const RECRUITER_ADDED_TO_BOOKMARK = 'added_to_bookmark';
export const RECRUITER_PROFILE_LINK_CLICK = 'profile_link_click';
export const RECRUITER_ONE_SEARCH_INITIATE = 'one_search_initiate';
export const RECRUITER_ONE_SEARCH_TRIGGERED = 'one_search_triggered';

export const RECRUITER_USER_TYPE_VARIABLE = 'user_type';
export const RECRUITER_TYPE_VALUES = {
  unknown: 'unknown',
  intermediary: 'intermediary',
  end_client: 'end_client',
};
export const IS_RECRUITER_VARIABLE = 'is_recruiter';
